.pac-container {
 background-color: #151515;
 font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.pac-item,
.pac-item-query {
 color: #f7f7f7;
}
.pac-item:hover {
 background: #6666;
}
